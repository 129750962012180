import { graphql, PageProps } from "gatsby";
import React, { FC, useMemo, useEffect, useRef } from "react";
import DetailLine from "../components/general/borders/DetailLine/DetailLine";
import PrimaryButton from "../components/general/button/PrimaryButton/PrimaryButton";
import SectionTitle from "../components/general/SectionTitle/SectionTitle";
import { ColorTheme } from "../data/enum/ColorTheme";
import { Contentstack404 } from "../data/types/ContentstackPages";
import { useIsMobile } from "../hooks/useIsMobile";
import { getWindowInnerHeight } from "../utils/getWindowInnerHeight";
import { isIE } from "../utils/browser-detect";
import styles from "../style/templates/404.module.scss";
import notFoundData from '../json/404.json';

interface NotFoundProps {
  allContentstack404: { nodes: Array<Contentstack404> };
}

const NotFoundPage: FC<PageProps<NotFoundProps>> = ({
  // data: {
  //   allContentstack404: {
  //     nodes: [
  //       {
  //         title,
  //         ctaText,
  //         backgroundImageDesktop: { url: desktopImageUrl },
  //         backgroundImageMobile: { url: mobileImageUrl },
  //         logo: { url: logoUrl },
  //       },
  //     ],
  //   },
  // },
}) => {
  const {
    allContentstack404: {
      nodes: [
        {
          title,
          ctaText,
          backgroundImageDesktop: { url: desktopImageUrl },
          backgroundImageMobile: { url: mobileImageUrl },
          logo: { url: logoUrl },
        },
      ],
    },
  } = notFoundData.result.data
  const pageNotFound = useRef<HTMLElement | null>(null);
  const titleLines = useMemo<Array<string>>(() => {
    const splitLines = title.split("-");
    return splitLines.length > 0 ? splitLines.map((line: any, index: number) => {
      return (index === 0 ? `${line}-` : line);
    }) : splitLines;
  }, [title]);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile && pageNotFound.current) {
      pageNotFound.current.style.height = getWindowInnerHeight();
    }
  }, [isMobile]);

  return (
    <section
      className={`container ${styles.pageNotFound}`}
      style={{ backgroundImage: `url(${isMobile && !isIE() ? mobileImageUrl : desktopImageUrl})` }}
      ref={pageNotFound}
    >
      <div className={`content-wrapper ${styles.contentWrapper}`}>
        <div className={styles.headingWrapper}>
          <img className={`${styles.mainLogo} ${!isMobile ? styles.white : ""}`} src={logoUrl} />
          <DetailLine
            className={styles.copyWrapper}
            variation="halfLeft"
            theme={isMobile ? ColorTheme.DARK : ColorTheme.LIGHT}
          >
            <SectionTitle
              className={styles.title}
              titleParts={titleLines}
              theme={isMobile ? ColorTheme.DARK : ColorTheme.LIGHT}
            />
          </DetailLine>
        </div>
        <PrimaryButton className={styles.backToHomeButton} to="/" label={ctaText} />
      </div>
    </section>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery($language: String!) {
    allContentstack404(filter: { publish_details: { locale: { eq: $language } } }) {
      nodes {
        title
        ctaText: cta
        backgroundImageDesktop: background_image_desktop {
          ...ContentstackAsset
        }
        backgroundImageMobile: background_image_mobile {
          ...ContentstackAsset
        }
        logo: logo_image {
          ...ContentstackAsset
        }
      }
    }
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;
